/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Rails from "@rails/ujs";
import { initFlashMessage } from "../utils/flash-message";

Rails.start();

document.addEventListener("DOMContentLoaded", () => {
  initFlashMessage();

  let prevScrollpos = window.scrollY;
  const header = document.querySelector(".jsScrollHeader");

  window.onscroll = function() {
    if (header) {
      const currentScrollPos = window.scrollY;

      if (prevScrollpos > currentScrollPos) {
        header.classList.remove("hide");
      } else {
        header.classList.add("hide");
      }

      prevScrollpos = currentScrollPos;
    }
  };

  const languageToggler = document.querySelector(".jsLanguageToggle");
  const languagesSublist = document.querySelector(".Header-languages-sublist");
  const submenuTogglers = document.querySelectorAll(".jsSubmenuListToggle");
  let hoverTimeout;

  const closeAllSubmenus = () => {
    document.querySelectorAll(".Header-submenu-list.active").forEach(submenu => {
      submenu.classList.remove("active");
    });
  };

  submenuTogglers.forEach(toggler => {
    toggler.addEventListener("mouseenter", function() {
      clearTimeout(hoverTimeout);

      const parentListItem = this.closest(".Header-menu-list-item");
      if (!parentListItem) return;

      const submenuList = parentListItem.querySelector(".Header-submenu-list");
      if (!submenuList) return;

      closeAllSubmenus();

      hoverTimeout = setTimeout(() => {
        if ("classList" in submenuList) submenuList.classList.add("active");
      }, 250);
    });

    toggler.addEventListener("mouseleave", function() {
      clearTimeout(hoverTimeout);

      const parentListItem = this.closest(".Header-menu-list-item");
      if (!parentListItem) return;

      const submenuList = parentListItem.querySelector(".Header-submenu-list");
      if (!submenuList) return;

      hoverTimeout = setTimeout(() => {
        if ("classList" in submenuList) submenuList.classList.remove("active");
      }, 600);
    });
  });

  const closeLanguageSublist = () => {
    if (languagesSublist && languagesSublist.classList.contains("active")) {
      languagesSublist.classList.remove("active");
    }

    if (languageToggler && languageToggler.classList.contains("active")) {
      languageToggler.classList.remove("active");
    }
  };

  if (languageToggler) {
    languageToggler.addEventListener("click", (event) => {
      event.preventDefault();
      languagesSublist.classList.toggle("active");
      languageToggler.classList.toggle("active");
      event.stopPropagation();
    });
  }

  document.addEventListener("click", closeLanguageSublist);

  const openMobileButton = document.querySelector(".jsOpenMobile");
  const closeMobileButton = document.querySelector(".jsCloseMobile");
  const mobileMenuWrapper = document.querySelector(".Header-mobile-menu");

  const openMobileMenu = () => {
    mobileMenuWrapper.classList.add("active");
    mobileMenuWrapper.style.transitionDuration = '0.4s';
    document.body.classList.add("no-scroll");
  };

  const closeMobileMenu = () => {
    mobileMenuWrapper.style.transitionDuration = '0.4s';
    mobileMenuWrapper.classList.remove("active");
    document.body.classList.remove("no-scroll");
  };

  if (openMobileButton) {
    openMobileButton.addEventListener("click", openMobileMenu);
  }

  if (closeMobileButton) {
    closeMobileButton.addEventListener("click", closeMobileMenu);
  }

  const mobileToggleButtons = document.querySelectorAll(".jsMobileToggleButton");

  mobileToggleButtons.forEach(button => {
    button.addEventListener("click", function(event) {
      event.preventDefault();

      const parentListItem = this.closest(".Header-mobile-menu-list-item");
      const mobileSubmenuList = parentListItem.querySelector(".Header-mobile-submenu-list");

      mobileSubmenuList.classList.toggle("active");

      event.stopPropagation();
    });
  });

  const mobileToggler = document.querySelector('.jsMobileToggler');
  const mobileMenu = document.querySelector('.jsMobileMenu');
  if (!(mobileToggler && mobileMenu)) return;

  const Keycode = {
    ESC: 27,
    TAB: 9
  };

  const closeMenu = function () {
    document.querySelectorAll("[data-tabindex]").forEach(element => {
      element.removeAttribute("tabindex");
    });
    mobileToggler.classList.remove("isActive");
    mobileMenu.classList.remove("isActive");
    document.querySelector(".jsMobileToggler").focus();
  }

  const openMenu = function () {
    document.querySelectorAll("[data-tabindex]").forEach(element => {
      element.setAttribute("tabindex", element.dataset.tabindex);
    });
    mobileToggler.classList.add("isActive");
    mobileMenu.classList.add("isActive");
    document.querySelector(".jsLogin").focus();
  }

  const popupEscHandler = function (e) {
    if (e.keyCode === Keycode.ESC) {
      closeMenu();
    }
  }

  document.addEventListener("keydown", popupEscHandler);

  mobileToggler.onclick = function() {
    if (mobileToggler.classList.contains("isActive")) {
      closeMenu();
    } else {
      openMenu();
    }
  }

  const deactivateParent = function(e) {
    e.parentElement.classList.remove("isActive");
  }

  document.querySelectorAll(".Header-listItem").forEach(element => {
    element.addEventListener("mouseenter", (e) => {
      document.querySelectorAll(".jsHeaderButton").forEach(deactivateParent);
      e.target.classList.add("isActive");
    });
    element.addEventListener("mouseleave", (e) => {
      e.target.classList.remove("isActive");
    });
  });

  document.querySelectorAll(".jsHeaderButton").forEach(element => {
    element.addEventListener("focus", (e) => {
      const parent = e.currentTarget.parentElement;
      document.querySelectorAll(".jsHeaderButton").forEach(deactivateParent);
      parent.classList.add("isActive");
    });
    element.addEventListener("blur", (e) => {
      const parent = e.currentTarget.parentElement;
      parent.classList.remove("isActive");
    });
  });
});
