/* Rails flash message on static pages */

const flashDuration = 5000;
const disappearDuration = 500; /* should be consistent with css transition */

const removeElement = (element: Element) => {
  if (!document.body.contains(element)) return;
  element?.parentElement?.classList.add("FlashMessage-wrapper--closing");
  setTimeout(() => { element.remove() }, disappearDuration);
}

const clickCloseHandler = (event: MouseEvent) => {
  const button = event.target as HTMLButtonElement;
  removeElement(button.parentNode?.parentNode as HTMLElement)
}

export const initFlashMessage = (): void => {
  document.querySelectorAll(".FlashMessage-close").forEach((button: Element) => {
    (button as HTMLButtonElement).addEventListener("click", clickCloseHandler);
    setTimeout(() => { removeElement(button.parentNode?.parentNode as HTMLElement) }, flashDuration);
  })
}
